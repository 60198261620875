import React, { useState }  from "react";

export default function Register() {

  const obrasSociales = [
    { CODE: '004', NAME: 'OSDE' },
    { CODE: '007', NAME: 'SANCOR SALUD' },
    { CODE: '008', NAME: 'OBSBA' },
    { CODE: '010', NAME: 'BRISTOL MEDICINE' },
    { CODE: '011', NAME: 'BASA SALUD' },
    { CODE: '012', NAME: 'PRINCIPAL PLAN' },
    { CODE: '014', NAME: 'FONDO COMPENSADOR TELEFONICOS' },
    { CODE: '015', NAME: 'AMPER' },
    { CODE: '016', NAME: 'OSTVENDRA' },
    { CODE: '018', NAME: 'OSLERA' },
    { CODE: '019', NAME: 'ALTA SALUD' },
    { CODE: '021', NAME: 'OSBA' },
    { CODE: '022', NAME: 'VIDA ACTIVA MUTUAL' },
    { CODE: '025', NAME: 'OSTEE' },
    { CODE: '027', NAME: 'OSPF' },
    { CODE: '028', NAME: 'MGN' },
    { CODE: '029', NAME: 'OSPAT' },
    { CODE: '030', NAME: 'SOMU' },
    { CODE: '031', NAME: 'OSPAGA' },
    { CODE: '032', NAME: 'OSPAGA' },
    { CODE: '033', NAME: 'OSPA' },
    { CODE: '034', NAME: 'OSPIT' },
    { CODE: '035', NAME: 'MEDICUS' },
    { CODE: '041', NAME: 'OSME' },
    { CODE: '043', NAME: 'OSAM' },
    { CODE: '049', NAME: 'AMFFA' },
    { CODE: '050', NAME: 'OSDEM' },
    { CODE: '056', NAME: 'MEDICAL COORP TRADE' },
    { CODE: '058', NAME: 'OSMTT' },
    { CODE: '066', NAME: 'MUTUAL 25 DE ABRIL' },
    { CODE: '068', NAME: 'ASSPE' },
    { CODE: '072', NAME: 'IOMA' },
    { CODE: '073', NAME: 'APSOT - TECHINT' },
    { CODE: '074', NAME: 'MUTUAL DE CONSUCTORES 5 DE AGS' },
    { CODE: '075', NAME: 'PARTICULAR' },
    { CODE: '076', NAME: 'OSPG' },
    { CODE: '079', NAME: 'VITAL' },
    { CODE: '080', NAME: 'CARGA Y DESCARGA' },
    { CODE: '081', NAME: 'GALENO' },
    { CODE: '082', NAME: 'ACTIVA SALUD' },
    { CODE: '083', NAME: 'PAMI VETERANOS DE GUERRA' },
    { CODE: '084', NAME: 'PERFUMISTAS' },
    { CODE: '086', NAME: 'OSPIB' },
    { CODE: '087', NAME: 'PREVENCION SALUD' },
    { CODE: '088', NAME: 'UTA' },
    { CODE: '089', NAME: 'VIASANO' },
    { CODE: '090', NAME: 'CREDIAL' },
    { CODE: '093', NAME: 'QUIMICOS' },
    { CODE: '094', NAME: 'GRUAS Y GUINCHEROS' },
    { CODE: '096', NAME: 'AMTAE' },
    { CODE: '097', NAME: 'OSVARA' },
    { CODE: '098', NAME: 'OSPIV' },
    { CODE: '099', NAME: 'PASEO CARD' },
    { CODE: '100', NAME: 'OSPP' },
    { CODE: '101', NAME: 'AMEBPBA(BCO.PROVINCIA)' },
    { CODE: '102', NAME: 'OSRJA' },
    { CODE: '103', NAME: 'OSPL(LADRILLEROS)' },
    { CODE: '104', NAME: 'SENDEROS' },
    { CODE: '108', NAME: 'POLICIA FEDERAL' },
    { CODE: '109', NAME: 'MADERERA' },
    { CODE: '110', NAME: 'SWISS MEDICAL' },
    { CODE: '111', NAME: 'PODER JUDICIAL' },
    { CODE: '112', NAME: 'HOSPITAL ALEMAN' },
    { CODE: '116', NAME: 'OSJERA' },
    { CODE: '117', NAME: 'OSETYA' },
    { CODE: '118', NAME: 'GERDANA' },
    { CODE: '119', NAME: 'UAI SALUD' },
    { CODE: '120', NAME: 'ALPHA Y OMEGA' },
    { CODE: '121', NAME: 'CASA' },
    { CODE: '122', NAME: 'FUTBOLISTAS' },
    { CODE: '123', NAME: 'ETICA SALUD' },
    { CODE: '124', NAME: 'CENTRO MEDICO PUEYRREDON' },
    { CODE: '125', NAME: 'YPF' },
    { CODE: '126', NAME: 'ENSALUD' },
    { CODE: '127', NAME: 'AMEBPBA' },
    { CODE: '128', NAME: 'FAD MEDICAL' },
    { CODE: '129', NAME: 'COLON' },
    { CODE: '130', NAME: 'OSPSIP' },
    { CODE: '131', NAME: 'BINIMED' },
    { CODE: '132', NAME: 'VISITAR' },
    { CODE: '133', NAME: 'MEDICALS' },
    { CODE: '134', NAME: 'LUIS PASTEUR' },
    { CODE: '135', NAME: 'CELIUS' },
    { CODE: '136', NAME: 'CORPORACION MEDICA ASIST' },
    { CODE: '137', NAME: 'RESPUESTA MEDICA' }
];


  const [selectedObraSocial, setSelectedObraSocial] = useState(''); // Estado para el valor seleccionado


  const mostrarAlerta = event => {
    event.preventDefault(); // Evitar la recarga de la página
    alert('Error. Por favor, inténtelo más tarde.');
  };


  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                {/* <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Registrate con
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/facebook.svg").default}
                    />
                    Facebook
                  </button>
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button>
                </div> */}
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                {/* <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>O crea tus credenciales</small>
                </div> */}
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nombre completo
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Nombre"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      DNI
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Documento de identidad"
                    />
                  </div>


                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="obraSocial"
                    >
                      Obra Social
                    </label>
                    <select
                      id="obraSocial"
                      value={selectedObraSocial}
                      onChange={e => setSelectedObraSocial(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    >
                      <option value="">Selecciona una obra social</option>
                      {obrasSociales.map(obraSocial => (
                        <option key={obraSocial.CODE} value={obraSocial.CODE}>
                          {obraSocial.NAME}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Contraseña
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>

                  <div>
                    {/* <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Aceptos las {" "}
                        <a
                          href="#pablo"
                          className="text-lightBlue-500"
                          onClick={(e) => e.preventDefault()}
                        >
                          Politicas de privacidad
                        </a>
                      </span>
                    </label> */}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      onClick={mostrarAlerta}
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                    >
                      Crear tu cuenta
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
