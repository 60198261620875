import { Link } from "react-router-dom";

export default function News({ datos }) {
  return (
    < >
      {datos.map((item, index) => (
        <div
          key={index}
          className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"
        >
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="text-red p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                <i className="fas fa-award"></i>
              </div>
              <h6 className="text-xl font-semibold">{item.title}</h6>
              <p className="mt-2 mb-4 text-blueGray-500">{item.description}</p>
              {/* <Link to={item.link}>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                  <img
                    alt="..."
                    className="align-middle border-none max-w-full h-auto rounded-lg"
                    src={item.image}
                  />
                </div>
              </Link> */}

              <div className="flex items-center mb-2">
                {/* Agregar aquí tus estrellas de valoración, por ejemplo: */}
                <span className="mr-2" >⭐⭐⭐⭐⭐ </span> <div>{`Hace ${item.tiempo} Meses`}</div> 
              </div>
              
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
