import React, { useRef } from "react";
import { Link } from "react-router-dom";
import wallpaper from 'assets/img/wallpaper.webp';
import login from 'assets/img/login.jpg';
// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import News from "components/News/LandingNews.jsx";
import MapComponent from "components/Maps/OpenMap.js";

import DatePickerComponent from "components/DatePicker/index.js"

const datosNovedades = [
  {
    title: "katty r",
    description: "UN CONSULTORIO CON PROFESIONALES SUPERLATIVOS !! MAS DE UNA DECADA ATENDIENDOME CON ELLOS EL DR MARCELO CORNU Y EQUIPO SON LO MAS, ES MUY CIERTO QUE EL DR ES SUPER EMPATICO Y SOLIDARIO, POR ESO SU ESPECIALIZACION EN DISCAPACIDAD, CONOZCO PORQUE LIDERO UN ENORME GRUPO EN ESE AREA DE LA SALUD, DIOS LOS BENDIGA AHORA Y SIEMPRE !! AMEN",
    link: "/novedad1",
    tiempo: 3,
    image: `${login}`,
  },
  {
    title: "paula d",
    description: "Hace dos meses me atendieron en forma inmediata ante una emergencia, medicandome en foma acertada,y lo hicieron con mucho respeto y escuchando mi problema.. La atención y el trato: Impecable.",
    link: "/novedad2",
    tiempo: 3,
    image: `${login}`,
  },
  {
    title: "marcela s",
    description: "Fui de emergencia por una infección en un diente que me había inflamado la mejilla y sentía mucho dolor. Debo decir que la secretaria fue muy amable ya que me atendía por primera vez y me explicó cómo era el sistema con respecto a mí obra social. Sin perder tiempo me derivó con la doctora que me revisó y me explicó ,en detalle,cómo debía abordar el tratamiento ,ya que necesitaba una radiografía panorámica y después el tratamiento de conducto. En verdad el analgésico y el antibiótico que me dio la doctora por suerte me alivia la tortura del dolor que ni siquiera me dejaba dormir. Agradezco la profesionalidad con que actuaron y  lo aliviada que me siento con la medicación que me recetó la doctora.",
    link: "/novedad2",
    tiempo:4,
    image: `${login}`,
  }
];

export default function Landing() {
  const sectionRef1 = useRef(null);
  const sectionRef2 = useRef(null);
  const sectionRef3 = useRef(null);
  const sectionRef4 = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Navbar transparent scrollToSection={scrollToSection}
        sectionRef1={sectionRef1} sectionRef2={sectionRef2} sectionRef3={sectionRef3}  sectionRef4={sectionRef4}/>
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
              `url(${wallpaper})`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full  px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-4xl">
                    Soluciones Odontologicas de Vanguardia
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 99"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <div ref={sectionRef2}>
          <section className="pb-20 bg-blueGray-200 ">
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap">
                  
                <div className="relative flex flex-col min-w-0 mt-20 w-full">
                  <h1 className="text-blueGray-600 font-semibold text-5xl text-center" >
                    Testimonios
                  </h1>
                <br></br>
                </div>

                <News datos={datosNovedades} />
              </div>
            </div>
          </section>
        </div>
        
        <div ref={sectionRef1}> 
          <section className="relative py-10 " >
            <div
              className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-white fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>

            <div className="container mx-auto px-4">
              <div className="items-center flex flex-wrap">
                <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                  <img
                    alt="..."
                    className="max-w-full rounded-lg shadow-lg"
                    src="https://lh3.googleusercontent.com/p/AF1QipO-Vsc_cpueUh9sLoKXix1nS6TYV7VrVDuUd_16=w768-h768-n-o-v1"
                  />
                </div>
                <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                  <div className="md:pr-12">
                    <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                      <i className="fas fa-user-friends text-xl"></i>
                    </div>
                    <h3 className="text-3xl font-semibold">Nuestras especialidades:</h3>
                    {/* <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                      Estos son nuestros servicios:
                    </p> */}
                    <ul className="list-none mt-6">
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Exodoncia (extracciones)
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Prótesis fija
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Prótesis removible
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Blanqueamiento
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Perno y Corona
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Endodoncia (conductos)
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Prótesis implantóasistida
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Ortodoncia
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Implantología
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Operatoria dental
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            Periodoncia
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                              Cirugías dentales
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                              Odontopediatría
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fas fa-tooth"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                              Estomatología
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <DatePickerComponent /> */}

        <div ref={sectionRef3}>
          <section className="pb-20 bg-blueGray-200 -mt-24">
            <div className="container mx-auto px-4">

              <div className="flex flex-wrap items-center mt-32">
                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                  <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                    <i className="fas fa-user-friends text-xl"></i>
                  </div>
                  <h3 className="text-3xl mb-2 font-semibold leading-normal">
                    Nosotros
                  </h3>
                  <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  Nos dedicamos a brindar atención odontológica de alta calidad con un compromiso inquebrantable con la salud bucal. Nuestra experiencia y dedicación nos han convertido en un referente en la industria de la odontología.

                  </p>
                  <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  A lo largo de los años, hemos construido una reputación de confianza y excelencia en el cuidado dental. Nuestra historia refleja nuestro compromiso constante con la salud bucal y el bienestar de nuestros pacientes.

                  </p>
                  {/* <Link to="/" className="font-bold text-blueGray-700 mt-8">
                    Check Notus React!
                  </Link> */}
                </div>

                <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                    <img
                      alt="..."
                      src="https://lh3.googleusercontent.com/p/AF1QipN8puztjTW8Lw3UqnRSgfYuMJlk39JAfZ_qELh7=w960-h960-n-o-v1"
                      className="w-full align-middle rounded-t-lg"
                    />
                    <blockquote className="relative p-8 mb-4">
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 583 95"
                        className="absolute left-0 w-full block h-95-px -top-94-px"
                      >
                        <polygon
                          points="-30,95 583,95 583,65"
                          className="text-lightBlue-500 fill-current"
                        ></polygon>
                      </svg>
                      <h4 className="text-xl font-bold text-white">
                        Nuestro Equipo
                      </h4>
                      <p className="text-md font-light mt-2 text-white">
                        El equipo de profesionales de la salud bucal está comprometido con brindar atención de alta calidad a nuestros pacientes. Cada miembro de nuestro equipo está altamente calificado y dedicado a mejorar y mantener la salud dental de nuestros clientes.

                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div ref={sectionRef4}>
          <section className="relative py-20">
            <div
              className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-white fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>

            <div className="container mx-auto px-4">
              <div className="items-center flex flex-wrap">

                <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                  <MapComponent />

                </div>
                <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                  <div className="md:pr-12">
                    <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                      <i className="fa fa-users text-xl"></i>
                    </div>
                    <h3 className="text-3xl font-semibold">Contacto</h3>
                    <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    BGE Ramos Mejía Buenos Aires AR
                    Mariano Moreno 245
                    B1704
                    Argentina
                    </p>
                    <ul className="list-none mt-6">
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fa fa-phone"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            011 4654-8482
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fa fa-phone"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            011 4469-0757
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                              <i className="fa fa-phone"></i>
                            </span>
                          </div>
                          <div>
                            <h4 className="text-blueGray-500">
                            011 4658-3846
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

      </main>
      <Footer />
    </>
  );
}
