import React from 'react';
// import { Link } from 'react-router-dom';
import './WhatsAppButton.css'; 

import whatsappIcon from 'assets/img/whatsapp.svg';


const WhatsAppButton = () => {
    return (

        <a
        href="https://api.whatsapp.com/send?phone=5491154660577"
        target="_blank"
        rel="noopener noreferrer"
        >
        <div className="whatsapp-button">
        <img
            src={whatsappIcon}
            alt="WhatsApp"
            className="whatsapp-icon"
        />
        </div>
        </a>

    );
  };
  
  export default WhatsAppButton;
  
