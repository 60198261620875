import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { createPopper } from "@popperjs/core";

const PagesDropdown = ({ scrollToSection, sectionRef2, sectionRef1, sectionRef3, sectionRef4 }) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const location = useLocation();
  const history = useHistory();
  const isAuthPath = location.pathname.startsWith("/auth");

  return (
    <>
      <a
        className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        {isAuthPath ? (
          <Link to="/" onClick={() => history.push("/")}>
            Home
          </Link>
        ) : (
          "Menu"
        )}
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        {isAuthPath ? null : (
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            Nuestras secciones
          </span>
        )}
        {isAuthPath ? null : (
          <Link
            to="#"
            onClick={() => scrollToSection(sectionRef2)}
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
          >
            Testimonios
          </Link>
        )}
        {isAuthPath ? null : (
          <Link
            to="#"
            onClick={() => scrollToSection(sectionRef1)}
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
          >
            Especialidades
          </Link>
        )}
        {isAuthPath ? null : (
          <Link
            to="#"
            onClick={() => scrollToSection(sectionRef3)}
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
          >
            Nosotros
          </Link>
        )}
        {isAuthPath ? null : (
          <Link
            to="#"
            onClick={() => scrollToSection(sectionRef4)}
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
          >
            Contacto
          </Link>
        )}
          <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            Clientes
          </span>
          <Link
            to="/auth/login"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
          >
            Ingresar
          </Link>
          <Link
            to="/auth/register"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
          >
            Registrarse
          </Link>
      </div>
    </>
  );
};

export default PagesDropdown;
